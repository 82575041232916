<template>
  <a-select
      v-bind="$attrs"
      :disabled="disabled"
      showArrow
      :showSearch="showSearch"
      :maxTagCount="maxTagCount"
      :class="`edp-select edp-select-size-${size} edp-select-skin-${skin}`"
      :options="selectOptions"
      v-model="tempValue"
      :mode="multiple ? 'multiple' : 'default'"
      optionFilterProp="children"
      @change="changeEvent">
  </a-select>
</template>

<script>
import edpFormMixins from "@/components/e-form/edpFormMixins.js";
import catMixin from "@/utils/mixins/catMixin.js";
import { fetch } from "@/utils/request.js";

export default {
  name: "edp-select",
  mixins: [edpFormMixins, catMixin],
  props: {
    maxTagCount: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    skin: {
      type: String,
      default: () => 'default'
    },
    optionsType: {
      type: String,
    },
    options: {
      type: Array,
      default: () => []
    },
    catType: {
      type: String,
    },
    account: {
      type: String,
    },
    product: {
      type: String,
    },
    req: {
      type: String,
    },
    company: {
      type: Boolean
    },
    multiple: {
      type: Boolean
    },
    splitStr: {
      type: String
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    prjOid: {
      handler(val) {
        if (val) {
          if (this.account === 'prj' || this.product === 'prj') {
            this.getOptions()
          }
        }
      }
    },
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.getOptions()
      }
    }
  },
  data() {
    return {
      selectOptions: []
    }
  },
  created() {
    if (this.multiple) {
      this.tempValue = this.getValue(this.value)
      this.preValue = this.getValue(this.value)
    }
  },
  methods: {
    async getOptions() {
      this.selectOptions = []
      if (this.optionsType === 'cat') {
        this.selectOptions.push(...this.catListToOptions(this.options))
      } else {
        this.selectOptions.push(...this.options)
      }
      if (this.account) {
        if (this.account === 'all') {
          await fetch("post", "/cpyaccount/retrieve/bycompany").then(res => {
            if (res.status === 0) {
              for (let accountItem of res.data.datas) {
                accountItem.accounts.forEach(item => {
                  this.selectOptions.push({
                    label: item.title ? item.name + "-" + item.title : item.name,
                    value: item.oid,
                    key: item.oid
                  })
                })
              }
            }
          })
        } else if (this.account === 'prj') {
          if (this.prjOid === '2300000001') {
            await fetch("post", "/report/getAccount").then(res => {
              if (res.status === 0) {
                console.log(res.data)
                this.selectOptions.push(...res.data.map(item => {
                  return {
                    label: item.title ? item.name + "-" + item.title : item.name,
                    value: item.oid,
                    key: item.oid
                  }
                }))
              }
            })
          } else {
            await this.getProjectAccount().then(accounts => {
              this.selectOptions.push(...accounts)
            })
          }
        }
      }
      if (this.catType) {
        await this.getOneCat(this.catType).then(res => {
          this.selectOptions.push(...this.catListToOptions(res))
        })
      }
      if (this.product) {
        if (this.product === 'prj') {
          await this.getProjectProduct().then(res => {
            this.selectOptions.push(...res)
          })
        }
      }
      if (this.req) {
        if (this.req === 'prj') {
          await this.getProjectReq().then(res => {
            this.selectOptions.push(...res)
          })
        }
      }
      if (this.company) {
        await this.getCompany().then(res => {
          this.selectOptions.push(...res)
        })
      }
      return ''
    },
    async getCompany() {
      return fetch("post", "company/retrieve", { page_size: 999 }).then(res => {
        return res.data.datas.map(item => {
          return {
            label: item.company_name,
            value: item.oid,
            key: item.oid
          }
        })
      })
    },
    async getProjectReq() {
      if (this.prjOid) {
        return fetch("post", "prj/requirement/retrieve", { prjoid: this.prjOid, page_size: 999 }).then(res => {
          if (res.status === 0) {
            return Promise.resolve(res.data.datas.map(item => {
              return {
                label: item.req_name,
                value: item.oid,
                key: item.oid
              }
            }))
          } else {
            return Promise.reject()
          }
        })
      }
    },
    async getProjectProduct() {
      if (this.prjOid) {
        return fetch("post", "prj/product/retrieve/byprj", { prjoid: this.prjOid, page_size: 999 }).then(res => {
          if (res.status === 0) {
            return Promise.resolve(res.data.datas.map(item => {
              return {
                label: item.product_name,
                value: item.oid,
                key: item.oid
              }
            }))
          } else {
            return Promise.reject()
          }
        })
      }
      return Promise.reject()
    },
    async getProjectAccount() {
      if (this.prjOid) {
        return fetch("post", "/prj/userlist/list", { prjoid: this.prjOid }).then(res => {
          if (res.status === 0) {
            return Promise.resolve(res.data.map(item => {
              return {
                label: item.title ? item.name + "-" + item.title : item.name,
                value: item.user_oid,
                key: item.user_oid
              }
            }))
          } else {
            return Promise.reject()
          }
        })
      } else {
        return Promise.reject()
      }
    },
    getValue(value) {
      if (this.multiple) {
        if (value === "" || value === null || value === undefined) {
          return []
        } else if (typeof value === 'string') {
          return value.split(this.splitStr)
        }
      }
      return value
    },
    getSetBeforeValue(value) {
      if (this.multiple) {
        return value.join(this.splitStr)
      }
      return value
    },
    getLabel(value) {
      let findValue = null
      if (this.multiple) {
        findValue = value
      } else {
        findValue = [value]
      }
      findValue = findValue.map(value => {
        let find = this.selectOptions.find(i => i.value === value)
        if (find) {
          return find.label
        } else {
          return value
        }
      })
      return findValue.join(this.splitStr)
    },
    catListToOptions(catList) {
      return catList.map(item => {
        return {
          value: item.cat_code,
          label: item.cat_name,
          key: item.cat_code
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edp-select {
  width: var(--width, 200px);

  &-size-mini {
    --width: 150px;
    --height: 24px;
  }

  &-size-middle {
    --height: 32px;
  }

  &-skin-status {
    width: 75px;

    &.ant-select-disabled {
      /deep/ .ant-select-selection {
        background: white;
      }
    }

    /deep/ .ant-select-selection {
      border-radius: 4px;
      background-color: var(--primary-color);
      font-size: 13px;
      color: white;
      font-weight: 700;
      height: 22px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      border: none;
      box-shadow: none;

      &::after {
        content: " ";
        background-image: url("@{imgPrefix}switch_status.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90% auto;
        position: absolute;
        width: 16px;
        height: 100%;
        right: 2px;
        top: 0;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: white;
      }


      .ant-select-selection__rendered {
        line-height: 22px;
        margin: 0 21px 0 8px;

        .ant-select-selection-selected-value {
          line-height: 22px;
        }
      }
    }

    /deep/ .ant-select-arrow {
      display: none;
      color: var(--primary-color);
    }

    &.blue {
      /deep/ .ant-select-selection {
        background-color: #338fe5;
      }
    }

    &.yellow {
      /deep/ .ant-select-selection {
        background-color: #F0A100;
      }
    }

    &.green {
      /deep/ .ant-select-selection {
        background-color: #02D35F;
      }
    }

    &.gray {
      /deep/ .ant-select-selection {
        background-color: #909090;
      }
    }

    &.red {
      /deep/ .ant-select-selection {
        background-color: #ff0000;
      }
    }
  }

  &-skin-default {
    --offsetLeft: 8px;

    &.ant-select-disabled {
      /deep/ .ant-select-selection {
        background: white;
        --offsetLeft: 0px;
      }

      &:hover {
        /deep/ .ant-select-selection {
          border-color: rgba(0, 0, 0, 0);

          .ant-select-arrow {
            opacity: 0;
          }
        }
      }
    }

    /deep/ .ant-select-selection {
      height: var(--height, 32px);
      border-color: rgba(0, 0, 0, 0);
      color: #303030;
      font-size: 14px;
      font-weight: var(--fontWeight, 400);

      .ant-select-selection__rendered {
        height: calc(100%);
        line-height: 1;
        margin-left: 0;
        margin-right: 8px;
        transition: .3s margin;
        display: flex;
        align-items: center;
      }

      .ant-select-arrow {
        opacity: 0;
        transition: .3s opacity;
      }
    }

    &:hover, &.ant-select-open {
      /deep/ .ant-select-selection {
        border-color: var(--primary-color);

        .ant-select-selection__rendered {
          margin-left: var(--offsetLeft);
          margin-right: 20px;
        }

        .ant-select-arrow {
          opacity: 1;
        }
      }
    }

    &.ant-select-open {
      /deep/ .ant-select-selection {
        //box-shadow: 0 0 0 2px rgba(var(--primary-color), 0.2);
      }
    }
  }
}
</style>
