import {fetch} from "@/utils/request.js";
import {message} from "ant-design-vue";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";

export default {
  emits: ['change'],
  props: {
    size: {
      type: String,
      validator: (val) => ['mini', 'middle', 'big'].includes(val),
      default: () => 'middle'
    },
    value: {
      type: [String, Number, Array]
    },
    fieldName: {
      type: String,
      required: true
    },
    oid: {
      type: String
    },
    prjOid: {
    },
    proOid: {
    },
    buyCoreOid: { // 更新采购入库字段需要
      default: ""
    },
    db: {
      type: FieldUpdateDb,
      required: true
    },
    disabledAutoUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      tempValue: "",
      preValue: ""
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val !== this.tempValue) {
          this.tempValue = this.getValue(val)
          this.preValue = this.getValue(val)
        }
      }
    }
  },
  created() {
    this.tempValue = this.getValue(this.value)
    this.preValue = this.getValue(this.value)
  },
  methods: {
    isEq(a, b) {
      return a === b
    },
    getLabel(value) {
      return value
    },
    getValue(value) {
      return value
    },
    getSetBeforeValue(value) {
      return value
    },
    changeEvent() {
      if (this.disabledAutoUpdate) {
        return
      }
      if (this.isEq(this.tempValue, this.preValue)) {
        return
      }
      this.updateField()
    },
    setValue(value) {
      this.tempValue = value
      this.updateField()
    },
    updateField() {
      if (this.isEq(this.getSetBeforeValue(this.tempValue), this.getSetBeforeValue(this.preValue))) {
        this.$message.info("修改前后内容一致 无需更新")
        return
      }
      let params = {
        field_id: this.db.options.dbname,
        field_name: this.fieldName,
        field_value: this.getSetBeforeValue(this.tempValue),
        oid: this.oid,
        old_value: this.getLabel(this.preValue),
        new_value: this.getLabel(this.tempValue)
      }
      for (let paramsKey in params) {
        if (params[paramsKey] === undefined) {
          return console.error("edpForm 数据是空的:", paramsKey)
        }
      }
      let url = ""
      if (this.db.options.type === 'prj') {
        params.prjoid = this.prjOid
        url = "/prj/update/onefield"
      } else if (this.db.options.type === 'pro') {
        params.prooid = this.proOid
        url = "/pro/update/onefield"
      } else {
        url = this.db.options.type
        if (this.proOid) params.prooid = this.proOid
        if (this.buyCoreOid) params.buycore_oid= this.buyCoreOid
      }
      return fetch('post', url, params).then((res) => {
        if (res.status === 0) {
          message.success('修改成功！')
          this.$emit("input", this.tempValue)
          this.preValue = this.tempValue
          this.$emit('change', this.tempValue)
          return Promise.resolve()
        } else {
          message.error(res.message)
          this.tempValue = this.preValue
        }
      })
    }
  }
}
